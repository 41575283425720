<template>
    <ProductPackagingShowcaseBlock
        v-editable="blok"
        :data="productPackagingShowcase"
    />
</template>

<script setup lang="ts">
import ProductPackagingShowcaseType from '@types/ProductPackagingShowcaseType';
import ProductPackagingShowcaseCardType from '@types/ProductPackagingShowcaseCardType';
import ButtonType from '@types/ButtonType';
import ProductPackagingShowcaseBlock from '~/components/page-building/product-packaging-showcase/ProductPackagingShowcaseBlock.vue';
import ImageType from '~/types/ImageType';
import LinkType from '~/types/LinkType';

const props = defineProps({
    blok: {
        type: Object,
        required: true,
    },
    nextBlock: {
        type: Object,
        required: false,
        default: null,
    },
});

const productPackagingShowcase = computed<ProductPackagingShowcaseType>(() => {
    const buttons = props.blok.buttons
        ? props.blok.buttons.map((button: any) => {
              return {
                  label: button.label,
                  style: button.style,
                  url: button.link.cached_url,
                  vEditable: button,
              } as ButtonType;
          })
        : [];

    const cards = props.blok.cards
        ? props.blok.cards.map((card: any) => {
              return {
                  image:
                      card.image && card.image.filename
                          ? ({
                                title: card.image.title,
                                alt: card.image.alt,
                                src: card.image.filename,
                            } as ImageType)
                          : null,
                  icon:
                      card.icon && card.icon.filename
                          ? ({
                                title: card.icon.title,
                                alt: card.icon.alt,
                                src: card.icon.filename,
                            } as ImageType)
                          : null,
                  hoverIcon:
                      card.hover_icon && card.hover_icon.filename
                          ? ({
                                title: card.hover_icon.title,
                                alt: card.hover_icon.alt,
                                src: card.hover_icon.filename,
                            } as ImageType)
                          : null,
                  title: card.title,
                  link: card.link
                      ? ({
                            label: card.link.label,
                            url: card.link.cached_url,
                        } as LinkType)
                      : null,
                  animationType: card.animation_type
                      ? card.animation_type
                      : null,
                  vEditable: card,
              } as ProductPackagingShowcaseCardType;
          })
        : [];

    return {
        defaultImage:
            props.blok.default_image && props.blok.default_image.filename
                ? ({
                      title: props.blok.default_image.title,
                      alt: props.blok.default_image.alt,
                      src: props.blok.default_image.filename,
                  } as ImageType)
                : null,
        heading: props.blok.heading,
        description: richTextToHtml(props.blok.description) as string,
        stackCardsOnMobile: props.blok.stack_cards_on_mobile,
        cards,
        buttons,
        backgroundColor: props.blok.background_color,
        nextBackgroundColor: props.nextBlock
            ? props.nextBlock.background_color
            : null,
        bottomAngle: props.blok.bottom_angle,
        arrowCallout: props.blok.arrow_callout_type
            ? {
                  type: props.blok.arrow_callout_type,
                  text: richTextToHtml(props.blok.arrow_callout_text) as string,
              }
            : null,
        reverseLayout: props.blok.reverse_layout,
    } as ProductPackagingShowcaseType;
});
</script>
